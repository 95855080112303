import React from "react"
import styled from "styled-components"
import { colors } from "../colors/colors"
import { Link } from "gatsby"
import HeaderLogo from "../SVGs/headerLogo"
import Slash from "../../components/SVGs/slash"
import { ProjectPageHeader } from "./ProjectPageHeader"
import { ProjectHeaderContainer } from "./ProjectHeaderContainer"

const LinkHolder = styled.div`
  grid-column: 1/2;

  @media (max-width: 650px) {
    grid-row: 2/3;
  }
`
const LogoHolder = styled(LinkHolder)`
  height: 75px;
  width: 75px;

  @media (max-width: 380px) {
    grid-row: 1/3;
    justify-self: start;
  }
`

const ProjectLinks = styled(Link)`
  text-decoration: none;
  font-style: italic;
  font-size: 20px;
  color: ${colors.white};
  display: inline-block;
  margin: 2px;

  &:hover {
    color: ${colors.brightRed};
  }

  @media (max-width: 812px) {
    font-size: 18px;
  }
`
const PrevLink = styled(ProjectLinks)`
  margin-right: 3px;
`

const NextLink = styled(ProjectLinks)`
  margin-left: -1px;
`
const HomeLink = styled(ProjectLinks)`
  height: 75px;
  width: 75px;
`
const ProjectName = styled.div`
  grid-column: 2/3;
  display: flex;

  @media (max-width: 650px) {
    grid-column: 1/4;
    grid-row: 1/2;
    > h1 {
      font-size: 24px;
    }
  }

  @media (max-width: 812px) {
    > h1 {
      font-size: 24px;
    }
  }
`

const ArchivedProjectName = styled.div`
  grid-column: 2/3;

  @media (max-width: 650px) {
    justify-self: end;
    align-self: center;
    grid-row: 1/3;
    grid-column: 2/3;
    > h1 {
      font-size: 18px;
    }
  }

  @media (max-width: 812px) {
    > h1 {
      font-size: 24px;
    }
  }
`

const ForwardBack = styled.nav`
  grid-column: 3/4;
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 100%;

  @media (max-width: 650px) {
    grid-row: 2/3;
    justify-self: end;
  }

  @media (max-width: 812px) {
    justify-self: end;
  }
`
const Divider = styled.div`
  height: 30px;
  padding-top: 3px;
  align-self: center;
`

const ProjectHeader = ({ title, previous, next, archive, local, pathBack }) => {
  const backArrow = "< "
  const forwardArrow = " >"
  const nextButton = " Next "
  const prevLink = " Prev "
  
  return (
    <ProjectPageHeader role="header">
      <ProjectHeaderContainer>
        {archive === true || local ? (
          <LogoHolder>
            <HomeLink to="/home">
              <HeaderLogo />
            </HomeLink>
          </LogoHolder>
        ) : (
          <LinkHolder>
            <ProjectLinks to="/projects">
              {backArrow} Back to Projects
            </ProjectLinks>
          </LinkHolder>
        )}

        {archive === true && !local ? (
          <ArchivedProjectName>
            <h1>{title}</h1>
          </ArchivedProjectName>
        ) : (
          <ProjectName>
            <h1>{title}</h1>
          </ProjectName>
        )}

        {local && (
          <ProjectLinks to={pathBack}>Back to Presentation</ProjectLinks>
        )}
        {!local && archive === true ? (
          <></>
        ) : (
          !local && (
            <ForwardBack>
              {next && (
                <div>
                  <PrevLink to={`/projects/${next.slug.current}`} rel="prev">
                    {backArrow}
                    {prevLink}
                  </PrevLink>
                </div>
              )}
              <Divider>
                <Slash />
              </Divider>
              {previous && (
                <div>
                  <NextLink to={`/projects/${previous.slug.current}`} rel="next">
                    {nextButton}
                    {forwardArrow}
                  </NextLink>
                </div>
              )}
            </ForwardBack>
          )
        )}
      </ProjectHeaderContainer>
    </ProjectPageHeader>
  )
}

export default ProjectHeader
