import React from "react"

const Slash = () => {
  return (
    <svg
      width="10"
      height="24"
      viewBox="0 0 10 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <line
        x1="8.9442"
        y1="0.329371"
        x2="1.36866"
        y2="22.046"
        stroke="#fffaf8"
        // stroke-width="2"
      />
    </svg>
  )
}

export default Slash
