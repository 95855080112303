import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import ProjectHeader from "../components/header-components/projectHeader"
import VideoProject from "../components/projectComponents/videoProject/videoProject"
import PhotoProjectPage from "../components/projectComponents/photo/photoProject"
import { ProjectContainer } from "./styled-comps/ProjectContainer"
import { ProjectInfo } from "./styled-comps/ProjectInfo"

class BlogPostTemplate extends React.Component {
  render() {
    const post = this.props.data.sanityPost
    const siteTitle = this.props.data.site.siteMetadata.title
    const { previous, next } = this.props.pageContext

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO
          title={post.title}
          description={post._rawDescription || post._rawBody}
        />
        <ProjectContainer>
          {this.props.location.state ? (
            <ProjectHeader
              local={this.props.location.state.fromPres}
              pathBack={this.props.location.state.pathBack}
              title={post.title}
              previous={previous}
              next={next}
            />
          ) : (
            <ProjectHeader title={post.title} previous={previous} next={next} />
          )}

          <ProjectInfo>
            {post.projectType === "video" ? (
              <VideoProject
                title={post.title}
                videos={post.videoSet}
                text={post._rawBody}
                description={post._rawDescription}
              />
            ) : (
              <PhotoProjectPage
                title={post.title}
                text={post._rawBody}
                images={post.photoSet}
                description={post._rawDescription}
              />
            )}
          </ProjectInfo>
        </ProjectContainer>
      </Layout>
    )
  }
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    site {
      siteMetadata {
        title
        author
      }
    }
    sanityPost(slug: { current: { eq: $slug } }) {
      title
      projectType
      videoSet
      photoSet {
        asset {
          fluid {
            ...GatsbySanityImageFluid
          }
        }
      }
      _rawBody
      _rawDescription
      active
    }
  }
`
